export default {
  components: {},
  props: {
    shipper: Object,
  },
  data() {
    return {
      country: null,
      interval: null,
      isLoaded: false,
      opener: null,
      zasilkovnaInput: null,
    }
  },
  computed: {
    zasilkovnaApiKey() {
      return this.$store.state.globals?.settings?.integrations?.zasilkovna_api_key
    },
    scriptLoaderId() {
      return 'BranchSelectorZasilkovna__zasilkovnaScriptLoader'
    },
    modalOpenerId() {
      return 'BranchSelectorZasilkovna__zasilkovnaOpener'
    },
  },
  mounted() {
    this.onMounted()
  },
  destroyed() {
    clearInterval(this.interval)
  },
  methods: {
    onMounted() {
      this.setCountry()
      const modalOpener = document.getElementsByClassName(this.modalOpenerId)

      if (modalOpener.length === 0) {
        this.opener = document.createElement('div')
        this.opener.className = this.modalOpenerId
        this.opener.style.display = 'none'
        document.body.appendChild(this.opener)
      } else {
        this.opener = modalOpener[0]
      }

      this.isLoaded = !!window.packetaIsLoaded

      if (!this.isLoaded) {
        this.loadZasilkovna()
      }

      const oThis = this
      let oldValue = this.$refs?.zasilkovnaInput?.value
      this.interval = setInterval(function() {
        if (!('zasilkovnaInput' in oThis?.$refs)) clearInterval(oThis.interval)

        if (oThis.$refs?.zasilkovnaInput?.value !== oldValue) {
          oldValue = oThis.$refs?.zasilkovnaInput?.value
          oThis.change(oThis.$refs?.zasilkovnaInput?.value)
        }
      }, 100)
    },
    setCountry() {
      const countryObject = this.$store.state.globals.countries.find(
        country => country.alpha3 === this.$store.state.cart.input.country,
      )
      this.country = countryObject ? countryObject.alpha2.toLowerCase() : this.$store.state.globals.zasilkovna.country
    },
    async change(value) {
      const branch = await this.$store.dispatch('cart/GET_STORE_BRANCH_BY_REMOTE_ID', {
        shipperId: this.shipper.id,
        remoteId: Number(value),
      })
      this.$store.commit('cart/SET_BRANCH', branch)
    },
    loadZasilkovna() {
      window.packetaSelectorOpen = '.' + this.opener.className
      window.packetaSelectorBranchName = '.BranchSelectorZasilkovna__zasilkovnaBranchName'
      window.packetaSelectorBranchId = '.BranchSelectorZasilkovna__zasilkovnaBranchId'
      window.packetaCountry = this.country
      window.packetaWidgetLanguage = this.$i18n.locale
      window.packetaPrimaryButtonColor = '#39b54a'
      window.packetaBackgroundColor = '#FFFFFF'
      window.packetaFontColor = '#282829'
      window.packetaFontFamily = 'Arial'
      let script = document.createElement('script')
      script.className = this.scriptLoaderId
      script.src = 'https://widget.packeta.com/www/js/packetaWidget.js'
      script.setAttribute('data-api-key', this.zasilkovnaApiKey)
      document.body.appendChild(script)

      script.onload = () => {
        window.packetaIsLoaded = true
        this.isLoaded = true
        this.$emit('init')
      }
    },
    open() {
      if (this.isLoaded) {
        this.opener.click()
      } else {
        this.$on('init', () => {
          // Packeta lib ignores open requests within 500ms from script load
          setTimeout(() => {
            this.opener.click()
          }, 500)
        })
      }
    },
  },
}
